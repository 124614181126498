$toggle-switch-transition: all 100ms ease-in !default;

// must all be same units--start
$toggle-switch-bar-padding: 0 !default; // space between button and bar, can be negative value
$toggle-switch-button-width: 25px !default;

$toggle-switch-bar-height: ($toggle-switch-bar-padding * 2) + $toggle-switch-button-width !default;
$toggle-switch-bar-width: 50px !default; // width of switch bar
// must all be same units--end

$toggle-switch-bar-border-radius: 3px !default;
$toggle-switch-bar-border-width: 1px !default;
$toggle-switch-bar-focus-box-shadow: 0 0 3px 2px #66AFE6 !default;
$toggle-switch-bar-font-size: 12px !default;

$toggle-switch-button-border-width: 1px !default;
$toggle-switch-button-font-size: $toggle-switch-bar-font-size !default;

// Toggle Switch Off
$toggle-switch-bar-bg: $input-group-addon-bg !default;
$toggle-switch-bar-border-color: $input-group-addon-border-color !default;
$toggle-switch-bar-icon-color: $input-color !default;

$toggle-switch-button-bg: $input-bg !default;
$toggle-switch-button-border-color: $input-group-addon-border-color !default;
$toggle-switch-button-border-radius: 3px 0 0 3px !default;
$toggle-switch-button-icon-color: $input-color !default;

// Toggle Switch On
$toggle-switch-bar-on-bg: $brand-primary !default;
$toggle-switch-bar-on-border-color: $toggle-switch-bar-on-bg !default;
$toggle-switch-bar-on-icon-color: #FFF !default;

$toggle-switch-button-on-bg: #FFF !default;
$toggle-switch-button-on-border-color: $toggle-switch-bar-on-bg !default;
$toggle-switch-button-on-border-radius: 0 3px 3px 0 !default;
$toggle-switch-button-on-icon-color: $toggle-switch-bar-on-bg !default;