$badge-font-size: $label-font-size !default;
$badge-lexicon-icon-height: $label-lexicon-icon-height !default;
$badge-lexicon-icon-width: $label-lexicon-icon-width !default;
$badge-padding-bottom: $label-padding-bottom !default;
$badge-padding-left: $label-padding-left !default;
$badge-padding-right: $label-padding-right !default;
$badge-padding-top: $label-padding-top !default;

$badge-link-text-decoration: underline !default;

$badge-link-hover-text-decoration: none !default;

$badge-lg-font-size: $label-lg-font-size !default;
$badge-lg-lexicon-icon-height: $label-lg-lexicon-icon-height !default;
$badge-lg-lexicon-icon-width: $label-lg-lexicon-icon-width !default;
$badge-lg-padding-bottom: $label-lg-padding-bottom !default;
$badge-lg-padding-left: $label-lg-padding-left !default;
$badge-lg-padding-right: $label-lg-padding-right !default;
$badge-lg-padding-top: $label-lg-padding-top !default;

$badge-sm-font-size: $label-sm-font-size !default;
$badge-sm-lexicon-icon-height: $label-sm-lexicon-icon-height !default;
$badge-sm-lexicon-icon-width: $label-sm-lexicon-icon-width !default;
$badge-sm-padding-bottom: $label-sm-padding-bottom !default;
$badge-sm-padding-left: $label-sm-padding-left !default;
$badge-sm-padding-right: $label-sm-padding-right !default;
$badge-sm-padding-top: $label-sm-padding-top !default;

$badge-border-style: null !default;
$badge-border-width: null !default;

$badge-danger-bg: $brand-danger !default;
$badge-danger-border: null !default;
$badge-danger-color: null !default;
$badge-danger-hover-bg: null !default;
$badge-danger-hover-border: null !default;
$badge-danger-hover-color: null !default;

$badge-default-bg: null !default;
$badge-default-border: null !default;
// Deprecated `$badge-default-link-color` as of v1.0.16 and will be removed in v2.0.0
$badge-default-link-color: null !default;

$badge-default-color: $badge-default-link-color !default;
$badge-default-hover-bg: null !default;
$badge-default-hover-border: null !default;
$badge-default-hover-color: null !default;

$badge-info-bg: $brand-info !default;
$badge-info-border: null !default;
$badge-info-color: null !default;
$badge-info-hover-bg: null !default;
$badge-info-hover-border: null !default;
$badge-info-hover-color: null !default;

$badge-primary-bg: $brand-primary !default;
$badge-primary-border: null !default;
$badge-primary-color: null !default;
$badge-primary-hover-bg: null !default;
$badge-primary-hover-border: null !default;
$badge-primary-hover-color: null !default;

$badge-success-bg: $brand-success !default;
$badge-success-border: null !default;
$badge-success-color: null !default;
$badge-success-hover-bg: null !default;
$badge-success-hover-border: null !default;
$badge-success-hover-color: null !default;

$badge-warning-bg: $brand-warning !default;
$badge-warning-border: null !default;
$badge-warning-color: null !default;
$badge-warning-hover-bg: null !default;
$badge-warning-hover-border: null !default;
$badge-warning-hover-color: null !default;