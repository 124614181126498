$user-icon-xs-font-size: 8px !default;
$user-icon-xs-size: 16px !default;

$user-icon-xs-desktop-font-size: 8px !default;
$user-icon-xs-desktop-size: 22px !default;

$user-icon-sm-font-size: 9px !default;
$user-icon-sm-size: 22px !default;

$user-icon-sm-desktop-font-size: $font-size-small !default; // 12px
$user-icon-sm-desktop-size: 30px !default;

$user-icon-font-size: $font-size-base !default; // 14px
$user-icon-size: 34px !default;

$user-icon-lg-font-size: 13px !default;
$user-icon-lg-size: 34px !default;

$user-icon-lg-desktop-font-size: $font-size-large !default; // 18px
$user-icon-lg-desktop-size: 45px !default;

$user-icon-xl-font-size: 22px !default;
$user-icon-xl-size: 48px !default;

$user-icon-xl-desktop-font-size: 30px !default;
$user-icon-xl-desktop-size: 64px !default;

$user-icon-xl-desktop-font-size: 30px !default;
$user-icon-xl-desktop-size: 64px !default;

$user-icon-xxl-font-size: 37px !default;
$user-icon-xxl-size: 96px !default;

$user-icon-xxl-desktop-font-size: 50px !default;
$user-icon-xxl-desktop-size: 128px !default;

$user-icon-border-radius: 500px !default;
$user-icon-circle-border-radius: 500px !default;
$user-icon-rounded-border-radius: $border-radius-base !default;
$user-icon-square-border-radius: 0 !default;

// Skin

$user-icon-default-bg: $gray-light !default;

$user-icon-primary-bg: $brand-primary !default;

$user-icon-info-bg: $brand-info !default;

$user-icon-success-bg: $brand-success !default;

$user-icon-warning-bg: $brand-warning !default;

$user-icon-danger-bg: $brand-danger !default;