$breadcrumb-separator-font-family: null !default;
$breadcrumb-separator-font-weight: null !default;
$breadcrumb-separator-svg-icon: lx-icon(angle-right, $breadcrumb-color) !default;
$breadcrumb-separator-svg-icon-height: 0.75em !default;
$breadcrumb-separator-svg-icon-width: $breadcrumb-separator-svg-icon-height !default;

$breadcrumb-vertical-separator: normal !default;

$breadcrumb-active-font-weight: null !default;

$breadcrumb-font-size: null !default;
$breadcrumb-font-weight: null !default;
$breadcrumb-text-transform: null !default;

$breadcrumb-border-radius: null !default;
$breadcrumb-link-color: null !default;
$breadcrumb-link-hover-color: null !default;

$breadcrumb-link-hover-text-decoration: null !default;
$breadcrumb-link-text-decoration: null !default;