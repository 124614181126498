@import "variables/globals";

@import "variables/alerts";
@import "variables/breadcrumbs";
@import "variables/buttons";
@import "variables/cards";
@import "variables/dropdowns";
@import "variables/forms";
@import "variables/figures";
@import "variables/icons";
@import "variables/labels";
@import "variables/badges"; // Inherits from Labels
@import "variables/management-bar";
@import "variables/modals";
@import "variables/list-group";
@import "variables/loaders";
@import "variables/nameplates";
@import "variables/navs";
@import "variables/navbar";
@import "variables/pagination";
@import "variables/panels";
@import "variables/progress-bars";
@import "variables/toolbars";
@import "variables/side-navigation";
@import "variables/sidebar";
@import "variables/stickers";
@import "variables/tables";
@import "variables/timelines";
@import "variables/toggle-card";
@import "variables/toggle-switch";
@import "variables/tooltip";
@import "variables/type";
@import "variables/user-icons";