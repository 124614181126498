$multi-step-progress-bar-active-icon: "\f111" !default;
$multi-step-progress-bar-complete-icon: "\f00c" !default;
$multi-step-progress-bar-icon: "\f10c" !default;

$multi-step-progress-bar-color: #BBB !default;

$multi-step-progress-bar-active-color: $brand-primary !default;

$multi-step-progress-bar-complete-color: $gray !default;
$multi-step-progress-bar-complete-icon-color: $brand-primary !default;

$multi-step-progress-bar-divider-height: 1px !default;

$multi-step-progress-bar-fixed-width: 100px !default;

$progress-box-shadow: null !default;

$progress-bar-box-shadow: null !default;
$progress-bar-font-size: null !default;
$progress-bar-height: null !default;

$progress-bar-xs-border-radius: 2px !default;
$progress-bar-xs-font-size: 8px !default;
$progress-bar-xs-height: 8px !default;

$progress-bar-lg-border-radius: $border-radius-base !default;
$progress-bar-lg-font-size: 14px !default;
$progress-bar-lg-height: 32px !default;

$progress-bar-xl-border-radius: $border-radius-large !default;
$progress-bar-xl-font-size: 18px !default;
$progress-bar-xl-height: 52px !default;