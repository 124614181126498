@mixin color-btn($border) {
	border-color: $border;
}

@mixin color-btn-hover($bg, $border) {
	&:focus,
	&:hover {
		background-color: $bg;
		border-color: $border;
	}
}

@mixin color-btn-active($bg, $border) {
	&:active,
	&.active,
	&:active:focus,
	&:active:hover,
	&.active:focus,
	&.active:hover,
	.open > &.dropdown-toggle,
	.open > &.dropdown-toggle:focus,
	.open > &.dropdown-toggle:hover {
		background-color: $bg;
		border-color: $border;
	}
}