// Make a direct descendant of .flex-container to break to a new line, should be
// applied to direct descendant of .flex-container

@mixin break-flex-item() {
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	width: 100%;
}

// Make .flex-container stack all its direct descendants, should be applied to
// .flex-container

@mixin stack-flex-container() {
	-ms-flex-direction: column;
	flex-direction: column;

	.flex-item-full {
 		-ms-flex-preferred-size: auto;
 		flex-basis: auto;
	}
}