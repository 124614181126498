$pagination-border-width: 1px !default;

$pagination-item-padding-bottom: $padding-base-vertical !default;
$pagination-item-padding-left: $padding-base-horizontal !default;
$pagination-item-padding-right: $padding-base-horizontal !default;
$pagination-item-padding-top: $padding-base-vertical !default;

$pagination-item-padding: $pagination-item-padding-top $pagination-item-padding-right $pagination-item-padding-bottom $pagination-item-padding-left !default;

$pagination-line-height: $line-height-base !default;

$pagination-sm-font-size: $font-size-small !default;
$pagination-sm-line-height: $line-height-small !default;

$pagination-sm-item-padding-bottom: $padding-small-vertical !default;
$pagination-sm-item-padding-left: $padding-small-horizontal !default;
$pagination-sm-item-padding-right: $padding-small-horizontal !default;
$pagination-sm-item-padding-top: $padding-small-vertical !default;

$pagination-sm-item-padding: $pagination-sm-item-padding-top $pagination-sm-item-padding-right $pagination-sm-item-padding-bottom $pagination-sm-item-padding-left !default;

$pagination-lg-font-size: $font-size-large !default;
$pagination-lg-line-height: $line-height-large !default;

$pagination-lg-item-padding-bottom: $padding-large-vertical !default;
$pagination-lg-item-padding-left: $padding-large-horizontal !default;
$pagination-lg-item-padding-right: $padding-large-horizontal !default;
$pagination-lg-item-padding-top: $padding-large-vertical !default;

$pagination-lg-item-padding: $pagination-lg-item-padding-top $pagination-lg-item-padding-right $pagination-lg-item-padding-bottom $pagination-lg-item-padding-left !default;

// Skin

$pagination-border-radius: $border-radius-base !default;
$pagination-lg-border-radius: $border-radius-large !default;
$pagination-sm-border-radius: $border-radius-small !default;

$pagination-items-per-page-bg: null !default;
$pagination-items-per-page-border: transparent !default;
$pagination-items-per-page-color: null !default;

$pagination-results-color: null !default;