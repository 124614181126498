$label-font-size: 12px !default;
$label-font-weight: bold !default;
$label-lexicon-icon-height: 9px !default;
$label-lexicon-icon-width: $label-lexicon-icon-height !default;
$label-padding-bottom: 3px !default;
$label-padding-left: 7px !default;
$label-padding-right: 7px !default;
$label-padding-top: 3px !default;

$label-link-text-decoration: underline !default;

$label-link-hover-text-decoration: none !default;

$label-lg-font-size: 16px !default;
$label-lg-lexicon-icon-height: 13px !default;
$label-lg-lexicon-icon-width: $label-lg-lexicon-icon-height !default;
$label-lg-padding-bottom: 6px !default;
$label-lg-padding-left: 12px !default;
$label-lg-padding-right: 12px !default;
$label-lg-padding-top: 6px !default;

$label-sm-font-size: 9px !default;
$label-sm-lexicon-icon-height: 5px !default;
$label-sm-lexicon-icon-width: $label-sm-lexicon-icon-height !default;
$label-sm-padding-bottom: 2px !default;
$label-sm-padding-left: 7px !default;
$label-sm-padding-right: 7px !default;
$label-sm-padding-top: 2px !default;

$label-border-style: null !default;
$label-border-width: null !default;

$label-danger-border: null !default;
$label-danger-color: null !default;
$label-danger-hover-bg: null !default;
$label-danger-hover-border: null !default;
$label-danger-hover-color: null !default;

$label-default-border: null !default;
$label-default-color: null !default;
$label-default-hover-bg: null !default;
$label-default-hover-border: null !default;
$label-default-hover-color: null !default;

$label-info-border: null !default;
$label-info-color: null !default;
$label-info-hover-bg: null !default;
$label-info-hover-border: null !default;
$label-info-hover-color: null !default;

$label-primary-border: null !default;
$label-primary-color: null !default;
$label-primary-hover-bg: null !default;
$label-primary-hover-border: null !default;
$label-primary-hover-color: null !default;

$label-success-border: null !default;
$label-success-color: null !default;
$label-success-hover-bg: null !default;
$label-success-hover-border: null !default;
$label-success-hover-color: null !default;

$label-warning-border: null !default;
$label-warning-color: null !default;
$label-warning-hover-bg: null !default;
$label-warning-hover-border: null !default;
$label-warning-hover-color: null !default;