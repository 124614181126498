$collapse-icon-padding-left: null !default;
$collapse-icon-padding-right: 45px !default;

$collapse-icon-position-bottom: null !default;
$collapse-icon-position-left: null !default;
$collapse-icon-position-right: 15px !default;
$collapse-icon-position-top: 10px !default;

$help-icon-default-bg: $input-group-addon-bg !default;
$help-icon-default-color: lx-color-by-lightness($text-color, $help-icon-default-bg) !default;
$help-icon-default-hover-color: lx-color-by-lightness($text-color, $help-icon-default-bg, "secondary") !default;

$lexicon-icon-size: 16px !default;

$lexicon-icon-lg-size: 128px !default;
$lexicon-icon-md-size: 32px !default;
$lexicon-icon-sm-size: 7px !default;

$icon-monospaced-size: 32px !default;
$icon-monospaced-lexicon-icon-padding: lx-icon-padding($icon-monospaced-size, $lexicon-icon-size) !default;

$scale-lexicon-icon-margin-top: -0.13em !default;
$scale-lexicon-icon-size: 1em !default;