$nameplate-label-border-radius: 100px !default;
$nameplate-label-height: $input-height-base !default;

$nameplate-label-text-font-weight: 500 !default;
$nameplate-label-text-line-height: 32px !default;
$nameplate-label-text-max-width: 200px !default;

$nameplate-label-circle-border-radius: 100px !default;
$nameplate-label-rounded-border-radius: 3px !default;
$nameplate-label-square-border-radius: 0 !default;

// Skin

$nameplate-subheading-color: $gray-light !default;

$nameplate-label-link-dark-color: #23527C !default;
$nameplate-label-link-dark-hover-color: darken($nameplate-label-link-dark-color, 15%) !default;

$nameplate-label-default-bg: $navbar-default-bg !default;
$nameplate-label-default-color: $navbar-default-color !default;

$nameplate-label-inverse-bg: $navbar-inverse-bg !default;
$nameplate-label-inverse-color: $navbar-inverse-color !default;

$nameplate-label-primary-bg: $label-primary-bg !default;
$nameplate-label-primary-color: $label-color !default;

$nameplate-label-success-bg: $label-success-bg !default;
$nameplate-label-success-color: $label-color !default;

$nameplate-label-info-bg: $label-info-bg !default;
$nameplate-label-info-color: $label-color !default;

$nameplate-label-warning-bg: $label-warning-bg !default;
$nameplate-label-warning-color: $label-color !default;

$nameplate-label-danger-bg: $label-danger-bg !default;
$nameplate-label-danger-color: $label-color !default;