$nav-equal-height-height: 40px !default;

$nav-heading-color: $text-color !default;

$nav-link-padding-horizontal: 15px !default;
$nav-link-padding-vertical: 10px !default;

$nav-link-padding: $nav-link-padding-vertical $nav-link-padding-horizontal !default;

$nav-nested-link-active-bg: null !default;
$nav-nested-link-active-color: $link-hover-color !default;
$nav-nested-margins-margin-horizontal: $navbar-padding-horizontal !default;
$nav-nested-padding-horizontal: $navbar-padding-horizontal !default;